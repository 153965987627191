import { environment } from './environments/environment';

let AWSCongiguration = {};
AWSCongiguration.aws_user_pools_id = 'eu-west-1_R6vTcxOon';
AWSCongiguration.aws_user_pools_web_client_id = '3qqfp7shm7oimj6id2c2nmafun';
AWSCongiguration.domain = 'uk-hmrc-dev.auth.eu-west-1.amazoncognito.com';
AWSCongiguration.redirectSignOut = 'http://localhost:4200/landing-page';

if (environment.qa) {
  AWSCongiguration.aws_user_pools_id = 'eu-west-1_FAeFxUIfC';
  AWSCongiguration.aws_user_pools_web_client_id = '1nleoc2p0dogn6tf9hui7nlfu7';
  AWSCongiguration.domain = 'auth.users.trx-uk-trackandtrace-dentsutracking.com';
  AWSCongiguration.redirectSignOut = 'https://portal.trx-uk-trackandtrace-dentsutracking.com/landing-page';
}

if (environment.pre) {
  AWSCongiguration.aws_user_pools_id = 'eu-west-1_p04N4cvji';
  AWSCongiguration.aws_user_pools_web_client_id = '3evk3p5gd25f4pm47568mc27df';
  AWSCongiguration.domain = 'auth.users.qa-uk-trackandtrace-dentsutracking.com';
  AWSCongiguration.redirectSignOut = 'https://idissuer.qa-uk-trackandtrace-dentsutracking.com/landing-page';
}

if (environment.production) {
  AWSCongiguration.aws_user_pools_id = 'eu-west-2_eIamERrur';
  AWSCongiguration.aws_user_pools_web_client_id = '5snhi8piqf9knunsnqcplj2hbl';
  AWSCongiguration.domain = 'auth.users.uk-trackandtrace-dentsutracking.com';
  AWSCongiguration.redirectSignOut = 'https://idissuer.uk-trackandtrace-dentsutracking.com/landing-page';
}

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": AWSCongiguration.aws_user_pools_id,
    "aws_user_pools_web_client_id": AWSCongiguration.aws_user_pools_web_client_id,
    "oauth": {
        "domain": AWSCongiguration.domain,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": AWSCongiguration.redirectSignIn,
        "redirectSignOut": AWSCongiguration.redirectSignOut,
        "responseType": "code"
    }
};

export default awsmobile;
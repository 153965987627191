import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';

import { ModuleService, UserService } from '../../shared/backend/api/api';

@Component({
  selector: 'launcher',
  templateUrl: 'launcher.component.html',
  styleUrls: ['launcher.component.scss']
})
export class LauncherComponent implements OnInit, OnDestroy {
  public modules: any[] = [];
  public module: string;
  private src: string;
  private page: string;
  private timer: any;

  constructor(private router: Router,
      private moduleService: ModuleService,
      private activatedRoute: ActivatedRoute,
      private userService: UserService
      ) {
  }

  ngOnInit() {

    this.timer = setTimeout(() => {
      this.router.navigate(['/landing-page']);
    }, 10000);

    const queryParams = this.activatedRoute.snapshot['queryParams'];
    if (queryParams.timestamp) {

      var timestampDate = new Date((queryParams.timestamp  * 1) + 3000);
      var timestamp = timestampDate.getTime();
      var currentTimestamp = new Date().getTime();
      if (timestamp > currentTimestamp) {
        this.modules = [];

        this.moduleService.modulesList()
          .subscribe((result: any) => {
              var self = this;

              result.data.forEach(function(module) {
                  self.modules.push(module);
              });

              // get module params
              this.getParams(this.modules);

              // create module iframe
              this.jumpToModule();
          },
          err => {
              console.log(err);
              Auth.signOut({ global: true })
              .then(data => {
                this.cancelSetTimeout();
                this.router.navigate(['/landing-page']);
              })
              .catch(err => {
                this.cancelSetTimeout();
                var keys = Object.keys(localStorage);
                for (let i = 0; i < keys.length; i++) {
                  localStorage.removeItem(keys[i]);
                }
                this.router.navigate(['/landing-page']);
              });
          });
      }
      else {
        this.cancelSetTimeout();
        this.router.navigate(['/landing-page']);
      }
    } else {
      this.cancelSetTimeout();
      this.router.navigate(['/landing-page']);
    }
    
  }

  private getParams(modules: any[]) {
    const queryParams = this.activatedRoute.snapshot['queryParams'];

    // get module code
    this.module = queryParams.module;

    // get src from module code
    let module = modules.find(register => register.name === this.module);

    if (module)
      this.src = module.url;

    // get module init page
    this.page = queryParams.page;
  }

  private jumpToModule() {

    let command = { data: {} };
    
    var keys = Object.keys(localStorage);
    for (let i = 0; i < keys.length; i++) {
      command.data[keys[i]] = localStorage.getItem(keys[i]);
    }

    this.userService.userSetSession(command).subscribe((result: any) => {
      
      let key = result.data;

      this.cancelSetTimeout();

      // jump to the module page
      if (this.page) {
        window.location.href = this.src + '/' + this.page + "?key=" + key + "&module=" + this.module;
      } else {
        window.location.href = this.src + "?key=" + key + "&module=" + this.module;
      }
    },
    err => {
      console.log(err);
      Auth.signOut({ global: true })
      .then(data => {
        this.cancelSetTimeout();
        this.router.navigate(['/landing-page']);
      })
      .catch(err => {
        this.cancelSetTimeout();
        var keys = Object.keys(localStorage);
        for (let i = 0; i < keys.length; i++) {
          localStorage.removeItem(keys[i]);
        }
        this.router.navigate(['/landing-page']);
      });
    });
  }

  private cancelSetTimeout() {
    if(this.timer) {
      clearTimeout(this.timer);
    }
    
  }

  ngOnDestroy(): void {
    this.cancelSetTimeout();
  }

}

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
  }
)
export class AppBreadcrumbService {
  breadcrumbs: Observable<Array<any>>;
  private breadcrumbSubject: BehaviorSubject<Array<any>>;

  constructor(private router: Router, private translateService: TranslateService, private route: ActivatedRoute) {
    this.breadcrumbSubject = new BehaviorSubject<any[]>(new Array<any>());

    this.breadcrumbs = this.breadcrumbSubject.asObservable();

    this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.configureBreadcrumb();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {    
      return this.configureBreadcrumb();
    });
  }

  configureBreadcrumb() {
    const breadcrumbs = [];
    let currentRoute = this.route.root;
    let url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      // tslint:disable-next-line:no-shadowed-variable
      childrenRoutes.forEach(route => {
        if (route.outlet === 'primary') {
          const routeSnapshot = route.snapshot;
          
          if (routeSnapshot)
            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');

          if (route.snapshot) {
            if (route.snapshot.data.key)
              route.snapshot.data.title = this.translateService.instant('BREADCRUMB.' + route.snapshot.data.key);

            breadcrumbs.push({
              label: route.snapshot.data,
              url
            });
          }

          currentRoute = route;
        }
      });
    } while (currentRoute);

    this.breadcrumbSubject.next(Object.assign([], breadcrumbs));
  }
}
export * from './client.service';
import { ClientService } from './client.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './module.service';
import { ModuleService } from './module.service';
export * from './organization.service';
import { OrganizationService } from './organization.service';
export * from './registration.service';
import { RegistrationService } from './registration.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [ClientService, DashboardService, ModuleService, OrganizationService, RegistrationService, RoleService, UserService];

<h4 style="color: white">{{ 'MASTER_MENU.TITLE' | translate }}</h4>
<div class="master-menu-wr">
    <div *ngFor="let module of modules" class="master-menu-item">
        <div class="module-item-header">
            <div class="title-wr">
                <span class="icon-wr circle">
                    <i [className]="'module-icon ' + module.icon" aria-hidden="true"></i>
                </span>
                <h5>{{module.title}}</h5>
            </div>
            <div class="module-status">
                <span class="active-module" *ngIf="module.isActive; else inactive">{{ 'MASTER_MENU.ACTIVE_MODULE' | translate }}</span>
                <ng-template #inactive>
                    <span class="inactive-module">{{ 'MASTER_MENU.INACTIVE_MODULE' | translate }}</span>
                </ng-template>
            </div>
        </div>
        <div class="module-item-actions">
            <div class="module-description">{{module.description}}</div>
            <div class="module-links">
                <a class="view-module" href="{{module.url}}">{{ 'MASTER_MENU.VIEW_MODULE' | translate }}</a>
            </div>
        </div>
    </div>
</div>
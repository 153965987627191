import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/shared/backend';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'landing-page',
  templateUrl: 'landing-page.component.html',
  styleUrls: ['landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  public module: string;
  public deregistered: string;

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService) {
  }

  ngOnInit() {

    const queryParams = this.route.snapshot['queryParams'];
    this.module = queryParams.module;

    if (queryParams.key != null && queryParams.key != undefined) {
      this.userService.userGetSession(queryParams.key).subscribe((result: any) => {
        var keys = Object.keys(localStorage);
        for (let i = 0; i < keys.length; i++) {
          localStorage.removeItem(keys[i]);
        }
        var keys = Object.keys(result.data);
        for (let i = 0; i < keys.length; i++) {
          localStorage.setItem(keys[i], result.data[keys[i]]);
        }
        this.loadUser();
      },
      err => {
        console.log(err);
        this.goToLogin();
      });
    } else {
      this.loadUser();
    }
  }

  private loadUser() {

    Auth.currentAuthenticatedUser()
      .then(() => {
        this.route.queryParams.subscribe( (params) => {
          if (params['logout'] == true || params['logout'] == "true") {
            Auth.signOut({ global: true })
              .then(data => {
                this.router.navigate(['/landing/login']);
              })
              .catch(err => {
                console.log(err);
                this.goToLogin();
              });
          }
          else {
            this.router.navigate(['/dashboard'], { replaceUrl: true });
          }
        });

      })
      .catch((err) => {
        console.log(err);
        this.goToLogin();
      });
  }

  private goToLogin() {
    var keys = Object.keys(localStorage);
    for (let i = 0; i < keys.length; i++) {
      localStorage.removeItem(keys[i]);
    }

    // get URL query params
    this.route.queryParams.subscribe(queryParams => {
      this.module = queryParams.module;
      this.deregistered = queryParams.deregister;
    });

    // pass deregister param to login component
    if (this.deregistered == 'true') {
      this.router.navigate(
        ['/landing/login'],
        { queryParams: { deregister: 'true' } }
      );
    } else {
      this.router.navigate(['/landing/login']);
    }

  }
}

import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

export function setTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/core/', suffix: '.json'},
    {prefix: './assets/i18n/modules/', suffix: '.json'}
  ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient,
              public resources: { prefix: string, suffix: string }[] = [{
                prefix: '/assets/i18n',
                suffix: '.json'
              }]) {
  }

  public getTranslation(lang: string): any {
    return forkJoin(this.resources.map(config => {
      return this.http.get(`${config.prefix}${lang}${config.suffix}`);
    })).pipe(map(response => {
      return response.reduce((a, b) => {
        return Object.assign(a, b);
      });
    }));
  }
}

@NgModule({
    imports: [
      CommonModule,
      TranslateModule.forRoot({
         loader: {
           provide: TranslateLoader,
           useFactory: (setTranslateLoader),
           deps: [HttpClient],
         },
         isolate: false
       })
    ],
    exports: [
      CommonModule,
      TranslateModule
    ]
})
export class TranslatorModule {
     constructor(private translate: TranslateService) {
        translate.addLangs(['en-GB', 'es']);
        translate.setDefaultLang('en-GB');
        translate.use('en-GB');
    }
}

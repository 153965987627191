// Angular modules
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular localizations
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEn);
registerLocaleData(localeEs);

// PrimeNG modules
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from 'primeng/menu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { PasswordModule } from 'primeng/password';


import { SelectorModule} from '../app/shared/components/selector/selector.module';

// Third modules
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

// App Components event bus
import { NgEventBus } from 'ng-event-bus';

// Template modules
import {AppAsideModule, AppHeaderModule,
        AppFooterModule, AppSidebarModule } from '@coreui/angular';
import { AppBreadcrumbModule } from './shared/components/breadcrumb'

import { AuthInterceptor } from './shared/services/interceptor/authInterceptor.service';

import { Configuration } from './shared/backend';

// App routing module
import { AppRoutingModule } from './app.routing';

// import angular envirotment variables
import { environment } from '../environments/environment';

// App Shared modules
import { DirectivesModule} from './shared/directives/index'
import { ComponentsModule } from './shared/components/index';
import { TranslatorModule, PermissionService, ContextService, CountryService, EUCountryService } from './shared/services/index';
import { TokenRouteGuardService } from './shared/services/token-guard/token-route-guard.service';

import { LocaleService} from "./shared/services/locale/locale.service";

// Enums and filters module
import { PipesModule } from './shared/pipes/index';

// App root component
import { AppComponent } from './app.component';

// App components
import { CoreLayoutComponent } from './layouts';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LauncherComponent } from './views/launcher/launcher.component';

import { UserFormComponent } from './shared/components/user-form/user-form.component';

// APIS
import { APIS } from './shared/backend/api/api';

const APP_CONTAINERS = [
  CoreLayoutComponent
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    DirectivesModule,
    ComponentsModule,
    PipesModule,
    TranslatorModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    OverlayPanelModule,
    TableModule,
    SidebarModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    SplitButtonModule,
    ContextMenuModule,
    ToastModule,
    MenuModule,
    TieredMenuModule,
    SelectorModule,
    PasswordModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LauncherComponent,
    UserFormComponent
  ],
  providers: [{
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (LocaleService: { locale: string; }) => LocaleService.locale
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: Configuration,
      useFactory: () =>
          new Configuration({ basePath: environment.basePath})
    },
    NgEventBus,
    PermissionService,
    ContextService,
    CountryService,
    EUCountryService,
    ...APIS,
    AmplifyService,
    TokenRouteGuardService
  ],
  entryComponents: [
    UserFormComponent
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

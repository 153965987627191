// Angular modules
import { NgModule } from '@angular/core';

// App pipes
import { UserTypePipe } from './UserType.pipe';
import { OrganisationTypeRolePipe } from './OrganisationTypeRole.pipe';
import { OrganisationTypePipe } from './OrganisationType.pipe';
import { UserStatusPipe } from './UserStatus.pipe';
import { LanguageTypePipe } from './LanguageType.pipe';
import { CountryPipe } from './Country.pipe';
import { LocalDatePipe } from './LocaleDate.pipe';
import { LocalNumberPipe } from './LocaleNumber.pipe';
import { SeverityTypePipe } from './SeverityType.pipe';
import { SafePipe } from './safe/safe.pipe';
import { EOTypePipe } from './EOType.pipe';
import { ProductTypePipe } from './ProductType.pipe';
import { PermissionsTypePipe } from './PermissionType.pipe';

@NgModule({
  imports: [],
  declarations: [
    SafePipe,
    UserTypePipe,
    OrganisationTypeRolePipe,
    OrganisationTypePipe,
    UserStatusPipe,
    LanguageTypePipe,
    CountryPipe,
    LocalDatePipe,
    LocalNumberPipe,
    SeverityTypePipe,
    EOTypePipe,
    ProductTypePipe,
    PermissionsTypePipe
  ],
  exports: [
    SafePipe,
    UserTypePipe,
    OrganisationTypeRolePipe,
    OrganisationTypePipe,
    UserStatusPipe,
    LanguageTypePipe,
    CountryPipe,
    LocalDatePipe,
    LocalNumberPipe,
    SeverityTypePipe,
    EOTypePipe,
    ProductTypePipe,
    PermissionsTypePipe
  ]
})
export class PipesModule { }

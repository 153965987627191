import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
      if (request.url.includes('assets'))
         return next.handle(request);
    
      return from(Auth.currentSession())
          .pipe(
              switchMap((auth: any) => {
                let jwt = auth.idToken.jwtToken;
                let with_auth_request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${jwt}`
                    }
                });
                return next.handle(with_auth_request);
              }),
              catchError((err: any) => {
                
                if(err === 'No current user') {
                  return next.handle(request);
                }
                
                if(request.url.includes('user/session') && request.method == 'GET')
                {
                  return next.handle(request);
                }
                
                return throwError(err);
              })
          );
      
  }
}

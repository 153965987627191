// Angular modules
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Main Layout component
import { CoreLayoutComponent } from './layouts';

// App component
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LauncherComponent } from './views/launcher/launcher.component';

import { LandingPageComponent } from './views/landing-page/landing-page.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'launcher',
    component: LauncherComponent,
    data: {
      title: 'Portal Launcher'
    }
  },
  {
    path: 'landing-page',
    component: LandingPageComponent
  },
  {
    path: 'landing',
    loadChildren: () => import('./views/landing/landing.module').then(m => m.LandingModule),
    data: {
      title: 'UK GSCC Landing'
    }
  },
  {
    path: '',
    component: CoreLayoutComponent,
    data: {
      key: 'HOME_ROOT',
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'core',
        loadChildren: () => import('./views/modules/modules.module').then(m => m.ModulesModule)
      },
      {
        path: 'configuration',
        loadChildren: () => import('./views/modules/api-access/api-access.module').then(m => m.ApiAccessModule)
      },
      {
        path: 'legal',
        loadChildren: () => import('./views/modules/terms-and-privacy/terms-and-privacy.module').then(m => m.TermsAndPrivacyModule)
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EUCountryService {
    constructor(private http: HttpClient) {
    }

    findByLanguage(language: string) {
        return this.http.get<any>('assets/i18n/eu-countries/' + language + '.json');
    }
}

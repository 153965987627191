import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';

import { OrganizationResultSuccessResult, UserDetailResultSuccessResult } from '../../../shared/backend/model/models';
import { User } from '../../../shared/backend/model/user';

import { UserType } from '../../../shared/enums/UserType.enum';

import { UserService } from '../../../shared/backend/api/user.service';
import { ModuleService, OrganizationResult, OrganizationService } from 'app/shared/backend';

import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ContextService {
    private user: User;
    private language: string;
    private organization: OrganizationResult;

    constructor(private userService: UserService, private organizationService: OrganizationService, private moduleService: ModuleService) {
    }

    public setCognitoUser(user) {
      this.user = {};
      this.user.id = user.signInUserSession.idToken.payload['dentsu:id'];
      this.user.username = user.username;
      this.user.email = user.signInUserSession.idToken.payload['email'];
      this.user.userType = user.signInUserSession.idToken.payload['dentsu:userType'];
      this.user.organizationId = parseInt(user.signInUserSession.idToken.payload['dentsu:org']);
      this.user.eoCode = user.signInUserSession.idToken.payload['dentsu:eoCode'];
      this.user.firstName = user.signInUserSession.idToken.payload['dentsu:firstName'];
      this.user.lastName = user.signInUserSession.idToken.payload['dentsu:lastName'];
      this.user.lang = user.signInUserSession.idToken.payload['dentsu:lang'];
      this.user.roles = JSON.parse(user.signInUserSession.idToken.payload['dentsu:roles']);
      this.user.resources = JSON.parse(user.signInUserSession.idToken.payload['dentsu:resource_access']);
      this.user.privacyPolicy = user.signInUserSession.idToken.payload['dentsu:privacyPolicy'];
      this.user.termsConditions = user.signInUserSession.idToken.payload['dentsu:termsConditions'];
    }

    public async loadUser() {
      
      let u = await Auth.currentAuthenticatedUser();
      this.setCognitoUser(u);
    }

    public getUser$(): Observable<User> {
      return from(Auth.currentAuthenticatedUser()).pipe(
        switchMap(cognitoUser => {
          this.setCognitoUser(cognitoUser);
          return of(this.user);
        })
      );
    }

    public getUser(): User {
      if (this.user) {
        return this.user;
      } else {
        return {};
      }
  }

    public async loadOrganization() {
      let result = await (this.organizationService.organizationGetCurrent().toPromise());
      this.organization = result.data;
    }

    public getOrganization$(): Observable<OrganizationResult> {
      return this.organizationService.organizationGetCurrent().pipe(
        switchMap((res: OrganizationResultSuccessResult) => {
          this.organization = res.data;
          return of(res.data);
        })
      )
    }

    public getOrganization(): OrganizationResult {
        if (this.organization) {
          return this.organization;
        } else {
          return {};
        }
    }

    public setLanguage(language: string) {
        this.language = language;
    }

    public getLanguage() {
        return this.language;
    }

    public isAdmin(): boolean {
        if (this.user && this.user.userType == UserType.ADMIN)
            return true;

        return false;
    }

    public isGoverment(): boolean {
        if (this.user && this.user.userType == UserType.GOVERNMENT)
            return true;

        return false;
    }

    public isIndustry(): boolean {
        if (this.user && this.user.userType == UserType.INDUSTRY)
          return true;

        return false;
    }

    public getUserInfo(): Observable<UserDetailResultSuccessResult>{
        return this.userService.userGetInfo();
    }

    public hasPermission(permission: string): boolean {
        if (this.user && this.user.resources) {
          var keys = Object.keys(this.user.resources);
          for (let i = 0; i < keys.length; i++) {
            if(this.user.resources[keys[i]].permissions.includes(permission)) {
              return true;
            }
          }
        }
        return false;
    }

    public launcher(page: string, moduleToJump: string) {
    
      this.moduleService.modulesList()
        .subscribe((result: any) => {
            let modules = [];

            result.data.forEach(function(module) {
                modules.push(module);
            });

            let module = modules.find(register => register.name === moduleToJump);
            let src = '';
            if (module)
              src = module.url;
              
            // create module iframe
            this.jumpToModule(src, page, moduleToJump);
        },
        err => {
            console.log(err);
            Auth.signOut({ global: true })
            .then(data => {
              window.location.href = '/landing-page';
            })
            .catch(err => {
              
              var keys = Object.keys(localStorage);
              for (let i = 0; i < keys.length; i++) {
                localStorage.removeItem(keys[i]);
              }
              window.location.href = '/landing-page';
            });
        });
    }

    private jumpToModule(src: string, page: string, module: string) {

      let command = { data: {} };
      
      var keys = Object.keys(localStorage);
      for (let i = 0; i < keys.length; i++) {
        command.data[keys[i]] = localStorage.getItem(keys[i]);
      }
  
      this.userService.userSetSession(command).subscribe((result: any) => {
        
        let key = result.data;

        // jump to the module page
        if (page) {
          window.location.href = src + '/' + page + "?key=" + key + "&module=" + module;
        } else {
          window.location.href = src + "?key=" + key + "&module=" + module;
        }
      },
      err => {
        console.log(err);
        Auth.signOut({ global: true })
        .then(data => {
          window.location.href = '/landing-page';
        })
        .catch(err => {
          var keys = Object.keys(localStorage);
          for (let i = 0; i < keys.length; i++) {
            localStorage.removeItem(keys[i]);
          }
          window.location.href = '/landing-page'
        });
      });
    }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '../../backend/model/user';
import { PermissionType } from '../../enums/PermissionType.enum'
import { Resource } from '../../backend/model/resource';
import { ContextService } from "../../services/context/context.service";
import { UserType } from 'app/shared/enums/UserType.enum';
import { OrganizationResult } from 'app/shared/backend';
import { OrganizationStatus } from 'app/shared/enums/OrganizationStatus.enum';
import { switchMap } from 'rxjs/operators';
import { OrganizationType } from 'app/shared/enums/OrganizationType.enum';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  private organization: OrganizationResult;
  private user: User;
  private userType: string;
  private resources: { [key: string]: Resource; };
  private privacyPolicy: boolean;
  private termsConditions: boolean;

  constructor(private contextService: ContextService) {
  }

  getPersmissionByModule(module: string, type: string) {
      let permissionId: string;

      for (const [propertyKey, propertyValue] of Object.entries(PermissionType)) {
          if (!isNaN(Number(propertyValue))) {
              continue;
          }

          let permissionTokens = propertyValue.toString().split("_");

          if (permissionTokens[0] == module && permissionTokens[1] == type)
              permissionId = propertyKey;
      }

      return permissionId;
  }

  getPersmissionById(id: any) {
      let permission: any = {};

      for (const [propertyKey, propertyValue] of Object.entries(PermissionType)) {
          if (propertyKey == id) {
              let permissionTokens = propertyValue.toString().split("_");

              permission.module = permissionTokens[0];
              permission.type = permissionTokens[1];
          }
      }

      return permission;
  }

  public checkAccess$(permissionControl: string, type?: string): Observable<boolean> {
    return this.contextService.getUser$().pipe(
      switchMap((user: User) => {
        this.user =  user;
        this.userType = this.user.userType;
        this.resources = this.user.resources;
        this.privacyPolicy = this.user.privacyPolicy == 'True';
        this.termsConditions = this.user.termsConditions == 'True';
        return this.contextService.getOrganization$();
      }),
      switchMap(organization => {
        this.organization = organization;
          return of(this.checkPermissionAccess(permissionControl, type));
      })
    );
  }

  public checkAccess(permissionControl: string, type?: string): boolean {
    this.user =  this.contextService.getUser();
    this.userType = this.user.userType;
    this.resources = this.user.resources;
    this.privacyPolicy = this.user.privacyPolicy == 'True';
    this.termsConditions = this.user.termsConditions == 'True';
    this.organization =  this.contextService.getOrganization();

    return this.checkPermissionAccess(permissionControl, type);
  }

  private checkPermissionAccess(permissionControl: string, type?: string): boolean {

    if (permissionControl.startsWith('check:action:')) {

      if (this.user == undefined || this.user.id == undefined) { return false; }
      switch(permissionControl.replace('check:action:', '')) {

        case "gslp.mfa":
          return this.canUseMFA();

        case 'gslp.createUser':
          return this.canCreateUser();

        case 'gslp.updateUser':
          return this.canEditUser();

        case 'gslp.updateUserProfile':
          return this.canUpdateUserProfile();

        case 'gslp.changePassword':
          return this.canChangePassword();

        case 'gslp.getUser':
          return this.canGetUser();

        case 'gslp.listUser':
          return this.canListUser();

        case 'gslp.lisUserCsvExport':
          return this.canLisUserCsvExport();

        case 'gslp.activateUser':
          return this.canActivateUser();

        case 'gslp.deactivateUser':
          return this.canDeactivateUser();

        case 'gslp.deregisterUser':
          return this.canDeregisterUser();

        case 'gslp.createClient':
          return this.canCreateApiAccess();

        case 'gslp.updateClient':
          return this.canEditApiAccess();

        case 'gslp.getClient':
          return this.canGetApiAccess();

        case 'gslp.listClient':
          return this.canListApiAccess();

        case 'gslp.activateClient':
          return this.canActivateApiAccess();

        case 'gslp.deactivateClient':
          return this.canDeactivateApiAccess();

        case 'gslp.deregisterClient':
          return this.canDeregisterApiAccess();


        case 'gsel.listRegistry':
          return this.canListEo() || this.canListFacility() || this.canListMachine();

        case 'gsel.createEo':
          return this.canCreateEo();

        case 'gsel.updateEo':
          return this.canEditEo();

        case 'gsel.getEo':
          return this.canGetEo();

        case 'gsel.listEo':
          return this.canListEo();

        case 'gsel.activateEo':
          return this.canActivateEo();

        case 'gsel.deactivateEo':
          return this.canDeactivateEo();

        case 'gsel.deregisterEo':
          return this.canDeregisterEo();

        case 'gsel.approveEoRequest':
          return this.canApproveEoRequest();

        case 'gsel.rejectEoRequest':
          return this.canRejectEoRequest();


        case 'gsel.createFacility':
          return this.canCreateFacility();

        case 'gsel.updateFacility':
          return this.canEditFacility();

        case 'gsel.getFacility':
          return this.canGetFacility();

        case 'gsel.listFacility':
          return this.canListFacility();

        case 'gsel.activateFacility':
          return this.canActivateFacility();

        case 'gsel.deactivateFacility':
          return this.canDeactivateFacility();

        case 'gsel.deregisterFacility':
          return this.canDeregisterFacility();

        case 'gsel.approveFacilityRequest':
          return this.canApproveFacilityRequest();

        case 'gsel.rejectFacilityRequest':
          return this.canRejectFacilityRequest();


        case 'gsel.createMachine':
          return this.canCreateMachine();

        case 'gsel.updateMachine':
          return this.canEditMachine();

        case 'gsel.getMachine':
          return this.canGetMachine();

        case 'gsel.listMachine':
          return this.canListMachine();

        case 'gsel.activateMachine':
          return this.canActivateMachine();

        case 'gsel.deactivateMachine':
          return this.canDeactivateMachine();

        case 'gsel.deregisterMachine':
          return this.canDeregisterMachine();

        case 'gsel.approveMachineRequest':
          return this.canApproveMachineRequest();

        case 'gsel.rejectMachineRequest':
          return this.canRejectMachineRequest();


        case 'gsel.getOrganization':
          return this.canGetOrganization();

        case 'gsel.listOrganization':
          return this.canListOrganization();

        case 'gsel.updateOrganization':
          return this.canEditOrganization();

        case 'gsel.activateOrganization':
          return this.canActivateOrganization();

        case 'gsel.deactivateOrganization':
          return this.canDeactivateOrganization();

        case 'gsel.approveOrganization':
          return this.canApproveOrganization();

        case 'gsel.rejectOrganization':
          return this.canRejectOrganization();

        case 'gsel.getDashboard':
          return this.canGetGselDashboard();


        case 'gsdm.requestOrder':
          return this.canRequestUpUI() || this.canRequestAUI();
  
        case 'gsdm.listOrder':
          return this.canListUpUI() || this.canListAUI();
        
        case 'gsdm.getOrder':
          if (type == 'UNIT') {
            return this.canGetUpUI();
          }
          else if (type == 'AGGREGATED') {
            return this.canGetAUI();
          }
          else {
            return false;
          }

        case 'gsdm.reorderOrder':
          if (type == 'UNIT') {
            return this.canReorderRequestUpUI();
          }
          else if (type == 'AGGREGATED') {
            return this.canReorderRequestAUI();
          }
          else {
            return false;
          }
        
        case 'gsdm.cancelOrder':
          if (type == 'UNIT') {
            return this.canCancelUpUI();
          }
          else if (type == 'AGGREGATED') {
            return this.canCancelAUI();
          }
          else {
            return false;
          }

        case 'gsdm.viewProductCodes':
          return this.canViewProductCodes();

        case 'gsdm.requestUpUI':
          return this.canRequestUpUI();

        case 'gsdm.reorderRequestUpUI':
          return this.canReorderRequestUpUI();

        case 'gsdm.getUpUI':
          return this.canGetUpUI();

        case 'gsdm.listUpUI':
          return this.canListUpUI();

        case 'gsdm.verifyUpUI':
          return this.canVerifyUpUI();

        case 'gsdm.cancelUpUI':
          return this.canCancelUpUI();

        case 'gsdm.requestAUI':
          return this.canRequestAUI();

        case 'gsdm.reorderRequestAUI':
          return this.canReorderRequestAUI();

        case 'gsdm.getAUI':
          return this.canGetAUI();

        case 'gsdm.listAUI':
          return this.canListAUI();

        case 'gsdm.verifyAUI':
          return this.canVerifyAUI();

        case 'gsdm.cancelAUI':
          return this.canCancelAUI();

        case 'gsdm.downloadOrderCodes':
          return this.canDownloadOrderCodes();

        case 'gsdm.downloadProductCodes':
          return this.canDownloadProductCodes();
          
        case 'gsdm.getDashboard':
          return this.canGetGsdmDashboard();


        case 'gscr.uiSearchLookup':
          return this.canUiSearchLookup();
        
        case 'gscr.multipleUILookup':
          return this.canMultipleUILookup();
        
        case 'gscr.uiQueryBuilder':
          return this.canUiQueryBuilder();

        case 'gscr.exportCsvUISearch':
          return this.canExportCsvUISearch();
        
        case 'gscr.uiDetail':
          return this.canUiDetail();
        
        case 'gscr.map':
          return this.canMap();

        case 'gscr.eventSearchLookup':
          return this.canEventSearchLookup();
        
        case 'gscr.eventQueryBuilder':
          return this.canEventQueryBuilder();
        
        case 'gscr.exportCsvEventSearch':
          return this.canExportCsvEventSearch();

        case 'gscr.alertSubscription':
          return this.canAlertSubscription();
        
        case 'gscr.alertGovDashboard':
          return this.canAlertGovDashboard();
        
        case 'gscr.getEo':
          return this.canGSCRGetEo();

        case 'gscr.getFacility':
          return this.canGSCRGetFacility();
        
        case 'gscr.getMachine':
          return this.canGSCRGetMachine();


        case 'gsbi.accessToGsbi':
          return this.canAccessToGsbi();


        default:
          return false;

      }

    }

    if (permissionControl.startsWith('check:user:')) {

      if (this.user == undefined || this.user.id == undefined) { return false; }

      switch(permissionControl.replace('check:user:', '')) {

        case "isAdmin":
          return this.isAdmin();

        case "isGoverment":
          return this.isGoverment();

        case "isIndustry":
          return this.isIndustry();

        case "privacyPolicy":
          return this.checkPrivacyPolicy();

        case "termsConditions":
          return this.checkTermsConditions();

        case "privacyPolicyAndTermsConditions":
          return this.checkPrivacyPolicyAndTermsConditions();

      }

    }

    if (permissionControl.startsWith('check:organization:')) {

      if (this.organization == undefined || this.organization.id == undefined) { return false; }

      switch(permissionControl.replace('check:organization:', '')) {

        case "isValid":
          return this.isValidOrganization();

        case "isRejected":
          return this.isRejectedOrganization();

        case "isBillable":
          return this.isBillable();

        case "isManufacturerImporterOrganization":
          return this.isManufacturerImporterOrganization();

        case "isOtherTypeOrganization":
          return this.isOtherTypeOrganization();

        case "isServiceProviderOrganization":
          return this.isServiceProviderOrganization();

        case "isHmrcOrganization":
          return this.isHmrcOrganization();

        case "isAdminOrganization":
          return this.isAdminOrganization();
      }

    }

    return false;
  }


  // Orders
  private canRequestUpUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.requestUpUI")
      && [ UserType.INDUSTRY ].some(role => role == this.userType);
  }

  private canViewProductCodes() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.viewProductCodes")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canReorderRequestUpUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.reorderRequestUpUI")
      && [ UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetUpUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.getUpUI")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListUpUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.listUpUI")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canVerifyUpUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.verifyUpUI")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canCancelUpUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.cancelUpUI")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canRequestAUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.requestAUI")
      && [ UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canReorderRequestAUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.reorderRequestAUI")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetAUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.getAUI")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListAUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.listAUI")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canVerifyAUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.verifyAUI")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canCancelAUI() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.cancelAUI")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canDownloadOrderCodes() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.downloadOrderCodes")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canDownloadProductCodes() : boolean {
    return this.resources['uk-gsdm']?.permissions?.some(permission => permission == "gsdm.downloadProductCodes")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetGsdmDashboard() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsdm.getDashboard")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  // User

  private isAdmin(): boolean {
    if (this.user && this.user.userType == UserType.ADMIN)
        return true;
    return false;
  }

  private isGoverment(): boolean {
      if (this.user && this.user.userType == UserType.GOVERNMENT)
          return false;
      return true;
  }

  private isIndustry(): boolean {
      if (this.user && this.user.userType == UserType.INDUSTRY)
        return true;
      return false;
  }

  private checkPrivacyPolicy(): boolean {
    return this.privacyPolicy;
  }

  private checkTermsConditions(): boolean {
    return this.termsConditions;
  }

  private checkPrivacyPolicyAndTermsConditions(): boolean {
    return this.privacyPolicy && this.termsConditions;
  }

  // Organization

  private isManufacturerImporterOrganization(): boolean {
    return this.organization.status.id == OrganizationType.OPERATOR_TYPE_MANUFACTURER_IMPORTER;
  }

  private isOtherTypeOrganization(): boolean {
    return this.organization.status.id == OrganizationType.OPERATOR_TYPE_OTHER;
  }

  private isServiceProviderOrganization(): boolean {
    return this.organization.status.id == OrganizationType.OPERATOR_TYPE_SERVICE_PROVIDER;
  }

  private isHmrcOrganization(): boolean {
    return this.organization.status.id == OrganizationType.OPERATOR_TYPE_HMRC;
  }

  private isAdminOrganization(): boolean {
    return this.organization.status.id == OrganizationType.OPERATOR_TYPE_DENTSU_ORGANIZATION;
  }

  private isValidOrganization(): boolean {
    return this.organization.isActive && !this.organization.isDeleted && this.organization.status.id == OrganizationStatus.ORGANIZATION_STATUS_APPROVED;
  }

  private isRejectedOrganization(): boolean {
    return this.organization.status.id == OrganizationStatus.ORGANIZATION_STATUS_REJECTED;
  }

  private isBillable(): boolean {
    return this.organization.isBillable;
  }

  // MFA
  private canUseMFA() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.mfa")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  // Users
  private canCreateUser() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.createUser")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canEditUser() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.updateUser")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canUpdateUserProfile() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.updateUserProfile")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canChangePassword() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.changePassword")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetUser() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.getUser")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListUser() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.listUser")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canLisUserCsvExport() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.lisUserCsvExport")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canActivateUser() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.activateUser")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canDeactivateUser() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.deactivateUser")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canDeregisterUser() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.deregisterUser")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  // Api Access Requests
  private canCreateApiAccess() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.createClient")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canEditApiAccess() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.updateClient")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetApiAccess() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.getClient")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListApiAccess() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.listClient")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canActivateApiAccess() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.activateClient")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canDeactivateApiAccess() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.deactivateClient")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canDeregisterApiAccess() : boolean {
    return this.resources['uk-gslp']?.permissions?.some(permission => permission == "gslp.deregisterClient")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  // Organizations
  private canGetOrganization() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.getOrganization")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListOrganization() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.listOrganization")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canEditOrganization() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.updateOrganization")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canActivateOrganization() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.activateOrganization")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canDeactivateOrganization() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.deactivateOrganization")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canApproveOrganization() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.approveOrganization")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canRejectOrganization() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.rejectOrganization")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canGetGselDashboard() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.getDashboard")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  
  // Economic Operators
  private canCreateEo() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.createEo")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canEditEo() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.updateEo")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetEo() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.getEo")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListEo() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.listEo")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canActivateEo() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.activateEo")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canDeactivateEo() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.deactivateEo")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canDeregisterEo() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.deregisterEo")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  // Economic Operator Requests
  private canApproveEoRequest() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.approveEoRequest")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canRejectEoRequest() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.rejectEoRequest")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }

  // Facility
  private canCreateFacility() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.createFacility")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canEditFacility() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.updateFacility")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetFacility() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.getFacility")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListFacility() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.listFacility")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canActivateFacility() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.activateFacility")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canDeactivateFacility() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.deactivateFacility")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canDeregisterFacility() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.deregisterFacility")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  // Facility Requests
  private canApproveFacilityRequest() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.approveFacilityRequest")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canRejectFacilityRequest() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.rejectFacilityRequest")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }

  // Machine
  private canCreateMachine() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.createMachine")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canEditMachine() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.updateMachine")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canGetMachine() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.getMachine")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canListMachine() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.listMachine")
      && [ UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY ].some(role => role == this.userType);
  }
  private canActivateMachine() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.activateMachine")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canDeactivateMachine() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.deactivateMachine")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canDeregisterMachine() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.deregisterMachine")
      && [ UserType.ADMIN, UserType.INDUSTRY ].some(role => role == this.userType);
  }

  // Machine Requests
  private canApproveMachineRequest() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.approveMachineRequest")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }
  private canRejectMachineRequest() : boolean {
    return this.resources['uk-gsel']?.permissions?.some(permission => permission == "gsel.rejectMachineRequest")
      && [ UserType.ADMIN ].some(role => role == this.userType);
  }

  // GSCR
  private canUiSearchLookup() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.uiSearchLookup")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canMultipleUILookup() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.multipleUILookup")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canUiQueryBuilder() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.uiQueryBuilder")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canExportCsvUISearch() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.exportCsvUISearch")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canUiDetail() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.uiDetail")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canMap() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.map")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canEventSearchLookup() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.eventSearchLookup")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canEventQueryBuilder() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.eventQueryBuilder")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canExportCsvEventSearch() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.exportCsvEventSearch")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canAlertSubscription() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.alertSubscription")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canAlertGovDashboard() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.alertGovDashboard")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canGSCRGetEo() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.getEo")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canGSCRGetFacility() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.getFacility")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }
  private canGSCRGetMachine() : boolean {
    return this.resources['uk-gscr']?.permissions?.some(permission => permission == "gscr.getMachine")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }

  // GSBI
  private canAccessToGsbi() : boolean {
    return this.resources['uk-gsbi']?.permissions?.some(permission => permission == "gsbi.accessToGsbi")
      && [ UserType.ADMIN, UserType.GOVERNMENT ].some(role => role == this.userType);
  }

}
// Angular modules
import { NgModule } from '@angular/core';

// App pipes
import { UtcDirective } from './utc.directive';
import { PermissionControlDirective } from './permission-control.directive';

@NgModule({
  imports: [],
  declarations: [
    UtcDirective,
    PermissionControlDirective
  ],
  exports: [
    UtcDirective,
    PermissionControlDirective
  ]
})
export class DirectivesModule { }

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Hub } from 'aws-amplify';
import { timer } from 'rxjs';

// primeng components
import { DialogService } from 'primeng/dynamicdialog';

import { MessageService, MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

// ngx-translate components
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

// event bus service
import { NgEventBus } from 'ng-event-bus';

import { LocaleService } from '../../shared/services/locale/locale.service';

// app context service
import { Auth } from 'aws-amplify';
import { ContextService } from '../../shared/services/context/context.service';

// app environment
import { environment } from '../../../environments/environment';

// app portal menu items
import { navItems } from '../../_nav';

import { EventSeverity } from '../../shared/enums/EventSeverity.enum'
import { EventType } from '../../shared/enums/EventType.enum'

import { User } from '../../shared/backend/model/user';

const WATCH_DOG: number = 60000;

@Component({
  selector: 'core-layout',
  templateUrl: './core-layout.component.html',
  styleUrls: ['./core-layout.component.scss'],
  providers: [DialogService, MessageService, TranslateService ]
})
export class CoreLayoutComponent implements OnInit, OnDestroy {
  public showEnvironmentBadge: boolean = false;
  public environmentBadgeLabel: string;
  public sidebarOpened: boolean = false;

  public title: string;
  public landingPortalLink: string;

  public subscriptionRoute: any;
  public subscriptionExpirationToken: any;
  public subscriptionMessage: any;
  private eventType = EventType;
  private eventSeverity = EventSeverity;

  // control menu layouts
  public sidebarMinimized = false;
  public sidebarHidden = false;
  public masterMenuMinimized = false;
  public masterMenuHidden = false;

  public user: User = {};

  // sidebar menu items
  public portalItems: any[] = [];
  public items: MenuItem[];

  // current portal details
  public portalVersion:string = '';
  public portalVersionCommit:string = '';

  @ViewChild('portalMenu')
  portalMenu: Menu;

  @ViewChild('userMenu')
  userMenu: any;

  constructor(private http: HttpClient,
              private eventBus: NgEventBus,
              private router: Router,
              private elementRef:ElementRef,
              private zone: NgZone,
              private messageService: MessageService,
              private translateService: TranslateService,
              private localeService: LocaleService,
              private contextService: ContextService,
              private dialogService: DialogService) {

    // portal build version generated on the build - 7 character hash with the commit ID
    this.getPortalVersionJSON().subscribe(data => {
      this.portalVersionCommit = data.commit;
    });

    // set portal title
    this.title = environment.portalTitle;
    // set default language
    this.contextService.setLanguage('en-GB');
    // set default language
    this.translateService.use('en-GB');
    // set app locale from user language
    this.localeService.registerCulture('en-GB');

    // Used for listening to login events
    Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "cognitoHostedUI" || event === "signedIn") {
        // console.log(event);
        this.zone.run(() => this.router.navigate(['/dashboard']));
      }
    });
  }

  public getPortalVersionJSON(): Observable<any> {
    return this.http.get('./assets/version/version.json');
  }

  async ngOnInit() {
    this.displayBanner();

    await this.contextService.loadUser();
    this.user = this.contextService.getUser();
    await this.contextService.loadOrganization();

    this.initViewUKGSCC();
    this.configViewUKGSCC();
  }

  public displayBanner() {
    if (!environment.production) {
      this.showEnvironmentBadge = true;
      if (environment.pre) {
        this.environmentBadgeLabel = this.translateService.instant('ENV_INFO_BADGE.PREPROD_ENV');
      }
      if (environment.qa) {
        this.environmentBadgeLabel = this.translateService.instant('ENV_INFO_BADGE.QA_ENV');
      }
    }
  }

  public logoLink(event: any): void {
    this.landingPortalLink = environment.landingPortal;
    window.location.href = this.landingPortalLink;
  }

  public onLogoClick(event: any): void {
    // TEMP HARDCODED REDIRECT TO GSLP - US25080
    this.landingPortalLink = environment.landingPortal + '/landing-page';
    window.location.href = this.landingPortalLink;
  }

  initViewUKGSCC(): void {
    this.setTimerToRefreshToken();
  }

  configViewUKGSCC(): void {
    // deep clone portal menu object
    this.portalItems = JSON.parse(JSON.stringify(navItems));

    // configure portal menu (userType and language)
    this.configMenu();

    this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.configMenu();
    });

    // subscribe to message events
    this.subscriptionMessage = this.eventBus.on(this.eventType.MESSAGE).subscribe((event: any)=>{
      if (event.severity == this.eventSeverity.INFO) {
        this.messageService.add({severity: 'success', summary: event.title, detail: event.message});
      }
      else if(event.severity == this.eventSeverity.WARNING) {
        this.messageService.add({severity: 'warn', summary: event.title, detail: event.message});
      }
      else if(event.severity == this.eventSeverity.ERROR) {
        let errors: any = Object.values(event.error);
        let errorMessages: any[] = [];

        for (const element of errors) {
          if (element.code.includes('GSCC') ||
              element.code.includes('CONF') ||
              element.code.includes('REG') ||
              element.code.includes('ORD') ||
              element.code.includes('UM'))
            errorMessages.push({severity: 'error', sticky: true, closable: true, summary: event.title, detail: (element.code != "" ? element.code  + " - " : "")
              + (element.property != "" ? element.property + " - " : "")
              + (element.code != "" ? this.translateService.instant('DB_ERRORS_CORE.' + element.code) : "") })
          else
            errorMessages.push({severity: 'error', sticky: true, closable: true, summary: event.title, detail: (element.code != "" ? element.code  + " - " : "")
            + (element.property != "" ? element.property + " - " : "")
            + (element.code != "" ? this.translateService.instant('DB_ERRORS_MODULE.' + element.code) : "") })
        }

        if (errorMessages.length > 0) {
          this.messageService.addAll(errorMessages);
        } else {
          this.messageService.add({severity: 'error', summary: event.title, detail: 'Errors undefined', sticky: true, closable: true});
        }
      }
    });

    let items = [];

    if(this.contextService.hasPermission('gslp.updateUserProfile')){
      items.push({
        label: 'Your profile',
        icon: 'gscc-icon gscc-icon-27',
        command: (event) => this.onShowProfileClick(event)
      });
    }

    let subitems = [];

    // if(this.contextService.hasPermission('gslp.listModule')){
    //   subitems.push({label: 'Modules',
    //     command: () => {
    //         this.router.navigate(['core/modules'])
    //     }
    //   });
    // }

    if(this.contextService.hasPermission('gslp.listUser')){
      subitems.push({label: 'User Management',
        command: () => {
          this.router.navigate(['core/users'])
        }
      });
    }

    // if(this.contextService.hasPermission('gslp.listRole')){
    //   subitems.push({label: 'Roles',
    //     command: () => {
    //       this.router.navigate(['core/roles'])
    //     }
    //   });
    // }

    this.items = [
      ...items
      ,
      {
        separator:true
      },
      {
          label: 'Settings',
          icon: 'gscc-icon gscc-icon-63',
          items: [...subitems]
      },
      {
        separator: true
      },
      {
        label: 'Terms & Privacy',
        icon: 'gscc-icon gscc-icon-58',
        items: [
          {
            label: 'Terms and conditions',
            command: () => {
              this.router.navigate(['legal/terms-and-conditions'])
            }
          },
          {
            label: 'Privacy policy',
            command: () => {
              this.router.navigate(['legal/privacy-policy'])
            }
          }
        ]
      },
      {
        separator: true
      },
      {
        label: 'Logout',
        icon: 'gscc-icon gscc-icon-111',
        command: () => this.onLogoutClick()
      },
      {
        label: 'Version: ' + environment.portalVersion + ' -  '+ this.portalVersionCommit,
        styleClass: 'version-wr'
      }
    ];
  }

  configMenu(): void {
    // recursive portal menu
    const translatedNavs = this.portalItems.map(items => {
      this.translate(items);
      return items;
    });

    // refresh menu items
    this.portalItems = [];
    translatedNavs.forEach(val => this.portalItems.push(Object.assign({}, val)));
  }

  translate(item): void {
    if((item.attributes.userTypes != undefined && item.attributes.userTypes.length > 0 && !item.attributes.userTypes.includes(this.user.userType))
      || (item.attributes.permissions != undefined && item.attributes.permissions.length > 0 && !item.attributes.permissions.some(m => this.contextService.hasPermission(m)))) {
      item.attributes.hidden = true;
    }
    else {
      if ('key' in item) {
        const trans = this.translateService.instant('MENU_PORTAL.' + item.key);

        if (trans !== 'MENU_PORTAL.' + item.key) {
          item.name = trans;
        }
      }

      if (item.children && item.children.length > 0) {
        item.children.map((child: any) => this.translate(child));
      }
    }
  }

  ngAfterViewInit() {

    /* Logo link event listener */
    this.elementRef.nativeElement.querySelector('.navbar-brand img').addEventListener('click', this.onLogoClick.bind(this));

    if (this.sidebarHidden) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('sidebar-lg-show');
    }
  }

  public onMasterMenuMinimized(): void {
    this.masterMenuMinimized = true;
  }

  public onShow(event: any) {
    document.getElementsByClassName("p-component-overlay")[0].setAttribute('style', 'background: none');
  }

  onShowProfileClick(event: any) {
    this.router.navigate(['core/your-profile']);
    // window.location.href = 'https://gslp-fr.trx-uk-trackandtrace-dentsutracking.com/core/your-profile';
  }

  async onLogoutClick() {
    Auth.signOut({ global: true })
      .then(data => {
        this.router.navigate(['/landing-page']);
      })
      .catch(err => {
        var keys = Object.keys(localStorage);
        for (let i = 0; i < keys.length; i++) {
          localStorage.removeItem(keys[i]);
        }
        this.router.navigate(['/landing-page']);
      });
  }

  setTimerToRefreshToken(): void {
    Auth.currentSession().then((session) => {
      const currentTime = Date.now();
      const expirationToken = timer((session.getAccessToken().payload.exp * 1000) - currentTime - WATCH_DOG);
      this.subscriptionExpirationToken = expirationToken.subscribe(async (val: any) => {
        // console.log('Token will expired');
        const cognitoUser = await Auth.currentAuthenticatedUser();
        cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
          // console.log('session', err, session);
          const { idToken, refreshToken, accessToken } = session;
          // do whatever you want to do now
          this.setTimerToRefreshToken();
        });
      });
    })
    .catch(() =>
      this.onLogoutClick()
    );
  }

  public onSidebarClick(event: any) {
    if(event.target?.attributes['jumpto'] || event.target?.parentNode?.attributes['jumpto']){
      this.contextService.launcher('landing-page', event.target?.attributes['jumpto']?.value ?? event.target.parentNode.attributes['jumpto']?.value);
      event.preventDefault();
    }
  }

  public sidebarToggler(event: any) {
    this.sidebarOpened = !this.sidebarOpened;
  }

  ngOnDestroy(): void {
    if(this.subscriptionExpirationToken)
      this.subscriptionExpirationToken.unsubscribe();

    if (this.subscriptionMessage)
      this.subscriptionMessage.unsubscribe();
  }
}

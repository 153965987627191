import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

import { ModuleService } from '../../../shared/backend/api/api';

@Component({
    selector: 'master-menu',
    templateUrl: './master-menu.component.html',
    styleUrls: ['./master-menu.component.scss']
})
export class MasterMenuComponent {
    public modules: any[] = [];
    public settingsModuleURL: string = '';

    constructor(private translateService: TranslateService,
                private moduleService: ModuleService,
                private router : Router) {
        // refresh menu-master on language change
        this.translateService.onLangChange.subscribe((event: TranslationChangeEvent) => {
            this.translateModules();
        });

        // fill menu-master with modules
        this.loadModules();

        // get GSCM settings url
        this.getGlobalSettingsPortalURL();
    }

    private getIconFromModule(name: string) : string {
        if (name == 'UK-GSEL')
            return 'gscc-icon-109'
        else if (name == 'UK-GSCM')
            return 'gscc-icon-119';
        else if (name == 'UK-GSCM-EM')
            return 'gscc-icon-61'
        else if (name == 'UK-GSBI')
            return 'gscc-icon-33';
        else if (name == 'UK-GSTM')
            return 'gscc-icon-39';
        else if (name == 'UK-GSCR')
            return 'gscc-icon-25';
        else if (name == 'UK-GSPL')
            return 'gscc-icon-01';
        else if (name == 'UK-GSSE')
            return 'gscc-icon-01';
        else if (name == 'UK-GSPT')
            return 'gscc-icon-01';
        else if (name == 'UK-GSIM')
            return 'gscc-icon-82';
        else if (name == 'UK-GSAU')
            return 'gscc-icon-01';
        else if (name == 'UK-GSDM')
            return 'gscc-icon-40';
        else if (name == 'UK-GSCO')
            return 'gscc-icon-01';
        else if (name == 'UK-GSAG')
            return 'gscc-icon-01';
        else if (name == 'UK-GSLP')
            return 'gscc-icon-05';
    }

    private getPageFromModule(name: string) : string {
      if (name == 'UK-GSEL')
          return 'dashboard'
      else if (name == 'UK-GSCM')
          return 'dashboard';
      else if (name == 'UK-GSCM-EM')
          return ''
      else if (name == 'UK-GSBI')
          return '';
      else if (name == 'UK-GSTM')
          return '';
      else if (name == 'UK-GSCR')
          return '';
      else if (name == 'UK-GSPL')
          return '';
      else if (name == '')
          return '';
      else if (name == 'UK-GSPT')
          return '';
      else if (name == 'UK-GSIM')
          return '';
      else if (name == 'UK-GSAU')
          return '';
      else if (name == 'UK-GSDM')
          return 'dashboard';
      else if (name == 'UK-GSCO')
          return '';
      else if (name == 'UK-GSAG')
          return '';
      else if (name == 'UK-GSLP')
          return 'dashboard';
      
      return '';
  }

    private loadModules() {
        this.modules = [];

        this.moduleService.modulesList()
            .subscribe((result: any) => {
                var self = this;

                result.data.forEach(function(module) {
                    self.translateService.get('MODULES.' + module.name).subscribe((translate: any) => {
                      let page = self.getPageFromModule(module.name);
                      self.modules.push({name: module.name,
                                          title: translate.TITLE,
                                          description: translate.DESCRIPTION,
                                          isActive: module.isActive,
                                          icon: self.getIconFromModule(module.name),
                                          //url: window.location.origin + '/launcher?module=' + module.name + (page !== ''? '&page=' + page : '') + '&timestamp=' + new Date().getTime(),
                                          permissions: module.permissions,
                                          status: module.status == 1 ? self.translateService.instant('MASTER_MENU.ACTIVE') : self.translateService.instant('MASTER_MENU.UNACTIVE')});
                    });
                });

                this.getGlobalSettingsPortalURL();
        },
        err => {
            console.log(err);
        });
    }

    private getGlobalSettingsPortalURL() {
        const settingsModuleName = 'UK-GSLP';

        this.moduleService.modulesList()
            .subscribe((result: any) => {

                let settingsModule = result.data.filter( (module) => {
                    if (module.name === settingsModuleName) {
                      return module;
                    }
                });

                this.settingsModuleURL = settingsModule[0].url + '/core/modules';
        },
        err => {
            console.log(err);
        });
    }

    private translateModules() {
        var self = this;

        this.modules.forEach(function(module) {
            self.translateService.get('MODULES.' + module.name).subscribe((translate: any) => {
                module.title = translate.TITLE;
                module.description = translate.DESCRIPTION;
                module.status == 1 ? self.translateService.instant('MASTER_MENU.ACTIVE') : self.translateService.instant('MASTER_MENU.UNACTIVE');});
        });
    }
}

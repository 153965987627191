export enum PermissionType {
  "UK-GSLP_gslp.mfa" = 1,
  "UK-GSLP_gslp.createUser" = 2,
  "UK-GSLP_gslp.updateUser" = 3,
  "UK-GSLP_gslp.updateUserProfile" = 4,
  "UK-GSLP_gslp.changePassword" = 5,
  "UK-GSLP_gslp.getUser" = 6,
  "UK-GSLP_gslp.listUser" = 7,
  "UK-GSLP_gslp.lisUserCsvExport" = 8,
  "UK-GSLP_gslp.activateUser" = 9,
  "UK-GSLP_gslp.deactivateUser" = 10,
  "UK-GSLP_gslp.deregisterUser" = 11,
  "UK-GSLP_gslp.createClient" = 12,
  "UK-GSLP_gslp.updateClient" = 13,
  "UK-GSLP_gslp.getClient" = 14,
  "UK-GSLP_gslp.listClient" = 15,
  "UK-GSLP_gslp.activateClient" = 16,
  "UK-GSLP_gslp.deactivateClient" = 17,
  "UK-GSLP_gslp.deregisterClient" = 18,
  "UK-GSLP_gslp.listRole" = 19,
  "UK-GSLP_gslp.createUserForOtherOrgs" = 20,
  "UK-GSLP_gslp.createUserForAllOrgs" = 21,
  "UK-GSLP_gslp.createUserWithDiffRoles" = 22,
  "UK-GSLP_gslp.changeOtherUsersPasswords" = 23,

  "UK-GSEL_gsel.createEo" = 1000,
  "UK-GSEL_gsel.createFacility" = 1001,
  "UK-GSEL_gsel.createMachine" = 1002,
  "UK-GSEL_gsel.updateEo" = 1003,
  "UK-GSEL_gsel.updateFacility" = 1004,
  "UK-GSEL_gsel.updateMachine" = 1005,
  "UK-GSEL_gsel.getEo" = 1006,
  "UK-GSEL_gsel.getFacility" = 1007,
  "UK-GSEL_gsel.getMachine" = 1008,
  "UK-GSEL_gsel.listEo" = 1009,
  "UK-GSEL_gsel.listFacility" = 1010,
  "UK-GSEL_gsel.listMachine" = 1011,
  "UK-GSEL_gsel.activateEo" = 1012,
  "UK-GSEL_gsel.activateFacility" = 1013,
  "UK-GSEL_gsel.activateMachine" = 1014,
  "UK-GSEL_gsel.deactivateEo" = 1015,
  "UK-GSEL_gsel.deactivateFacility" = 1016,
  "UK-GSEL_gsel.deactivateMachine" = 1017,
  "UK-GSEL_gsel.approveEoRequest" = 1018,
  "UK-GSEL_gsel.approveFacilityRequest" = 1019,
  "UK-GSEL_gsel.approveMachineRequest" = 1020,
  "UK-GSEL_gsel.rejectEoRequest" = 1021,
  "UK-GSEL_gsel.rejectFacilityRequest" = 1022,
  "UK-GSEL_gsel.rejectMachineRequest" = 1023,
  "UK-GSEL_gsel.deregisterEo" = 1024,
  "UK-GSEL_gsel.deregisterFacility" = 1025,
  "UK-GSEL_gsel.deregisterMachine" = 1026,
  "UK-GSEL_gsel.verifyEo" = 1027,
  "UK-GSEL_gsel.verifyFacility" = 1028,
  "UK-GSEL_gsel.verifyMachine" = 1029,
  "UK-GSEL_gsel.updateOrganization" = 1030,
  "UK-GSEL_gsel.approveOrganization" = 1031,
  "UK-GSEL_gsel.rejectOrganization" = 1032,
  "UK-GSEL_gsel.getOrganization" = 1033,
  "UK-GSEL_gsel.listOrganization" = 1034,
  "UK-GSEL_gsel.activateOrganization" = 1035,
  "UK-GSEL_gsel.deactivateOrganization" = 1036,
  "UK-GSEL_gsel.getDashboard" = 1037,

  "UK-GSDM_gsdm.viewProductCodes" = 3000,
  "UK-GSDM_gsdm.requestUpUI" = 3001,
  "UK-GSDM_gsdm.reorderRequestUpUI" = 3002,
  "UK-GSDM_gsdm.getUpUI" = 3003,
  "UK-GSDM_gsdm.listUpUI" = 3004,
  "UK-GSDM_gsdm.verifyUpUI" = 3005,
  "UK-GSDM_gsdm.cancelUpUI" = 3006,
  "UK-GSDM_gsdm.requestAUI" = 3007,
  "UK-GSDM_gsdm.reorderRequestAUI" = 3008,
  "UK-GSDM_gsdm.getAUI" = 3009,
  "UK-GSDM_gsdm.listAUI" = 3010,
  "UK-GSDM_gsdm.verifyAUI" = 3011,
  "UK-GSDM_gsdm.cancelAUI" = 3012,
  "UK-GSDM_gsdm.downloadOrderCodes" = 3013,
  "UK-GSDM_gsdm.downloadProductCodes" = 3014,
  "UK-GSDM_gsdm.getDashboard" = 3015,

  "UK-GSCR_gscr.uiSearchLookup" = 4000,
  "UK-GSCR_gscr.multipleUILookup" = 4001,
  "UK-GSCR_gscr.uiQueryBuilder" = 4002,
  "UK-GSCR_gscr.exportCsvUISearch" = 4003,
  "UK-GSCR_gscr.uiDetail" = 4004,
  "UK-GSCR_gscr.map" = 4005,
  //"UK-GSCR_gscr.exportCsvUIAggregate" = 4006,
  "UK-GSCR_gscr.eventSearchLookup" = 4007,
  //"UK-GSCR_gscr.multipleEventLookup" = 4008,
  "UK-GSCR_gscr.eventQueryBuilder" = 4009,
  //"UK-GSCR_gscr.eventDetail" = 4010,
  "UK-GSCR_gscr.exportCsvEventSearch" = 4011,
  "UK-GSCR_gscr.alertSubscription" = 4012,
  "UK-GSCR_gscr.alertGovDashboard" = 4013,
  "UK-GSCR_gscr.getEo" = 4014,
  "UK-GSCR_gscr.getFacility" = 4015,
  "UK-GSCR_gscr.getMachine" = 4016,

  "UK-GSBI_gsbi.accessToGsbi" = 5000

}
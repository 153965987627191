import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

import { PrimeNGConfig } from 'primeng/api';

const DEFAULT_LANGUAGE: string = "en-GB";

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router,
              private translate: TranslateService,
              private primengConfig: PrimeNGConfig) { 
    this.translate.use(DEFAULT_LANGUAGE);
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);                             
  }

  ngOnInit() {
    // activate primeNG extra animations
    this.primengConfig.ripple = true;

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CountryService {

  private countryPromise : Promise<any>;

  constructor(private http: HttpClient) {
    let country$ = this.http.get<any>('assets/i18n/countries/en-GB.json');
    this.countryPromise = country$.toPromise();
  }

  setLanguage(language: string): void {
    let country$ = this.http.get<any>('assets/i18n/countries/'+ language +'.json');
    this.countryPromise = country$.toPromise();
  }

  getCountryFilterValue(event: any, countries: any) {
      if (event.target.value != null) {
        let filteredCountries = countries.filter(c => c.name.toLowerCase().includes(event.target.value.toLowerCase())).map(m => m.alpha2)
        if (filteredCountries?.length == 0) {
          filteredCountries = ['none'];
        }
        return filteredCountries;
      }
      else
        return countries;
  }

  async getCountryPromise(){
      return this.countryPromise;
  }
}

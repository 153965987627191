import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { PasswordModule } from 'primeng/password';
import { SelectorModule} from '../../shared/components/selector/selector.module';

import { TranslateModule } from "@ngx-translate/core";


import { MasterMenuComponent } from './master-menu/master-menu.component';
import { FilterToolbarComponent } from './filter-toolbar/filter-toolbar.component';

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      ButtonModule,
      DropdownModule,
      MultiSelectModule,
      TranslateModule,
      PasswordModule,
      SelectorModule
    ],
    declarations: [
      MasterMenuComponent,
      FilterToolbarComponent,
    ],
    exports: [
      MasterMenuComponent,
      FilterToolbarComponent,
    ]
  })
  export class ComponentsModule {
  }

<!-- Toolbar Content -->
<div class="header app-header navbar">
  <button type="button" class="d-lg-none navbar-toggler" (click)="sidebarToggler($event)">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" (click)="onLogoClick($event)">
    <img class="navbar-brand-full" src="assets/img/brand/uk-dentsu-tracking-white.svg" width="130" height="40" alt="UK Tobacco Track and Trace system" />
    <img class="navbar-brand-minimized" src="assets/img/brand/uk-dentsu-tracking-pictogram.svg" width="130" height="40" alt="UK Tobacco Track and Trace system" />
  </a>
  <ul class="nav navbar-nav left-nav">
     <li class="nav-item" style="display:none !important;">
        <a class="nav-link" [hidden]="masterMenuHidden" aria-label="Toggle menu" (click)="onMasterMenuMinimized()">
        <i class="icon-options-vertical" aria-hidden="true"></i>*
        </a>
     </li>
  </ul>
  <ul class="nav navbar-nav left-nav">
     <li class="nav-item" style="display:none !important;">
        <a class="nav-link" [hidden]="masterMenuHidden" aria-label="Toggle menu" (click)="onMasterMenuMinimized()">
        <i class="icon-options-vertical" aria-hidden="true"></i>
        </a>
     </li>
  </ul>
  <ul class="nav navbar-nav ml-auto right-nav">
     <li class="nav-item" id="env-info-badge" *ngIf="showEnvironmentBadge">
        <div class="nav-badge">
           <span class="icon-env-info" aria-hidden="true"></span>
           <span class="label">{{ environmentBadgeLabel }}</span>
        </div>
     </li>
     <li class="nav-item d-md-down-none">
        <a class="nav-link" aria-label="UK Track and Trace support website" href="https://support.uk-trackandtrace.dentsutracking.com/uksystem" target="_blank" rel="noopener"><i class="icon-support" aria-hidden="true"></i></a>
     </li>
     <li class="nav-item d-md-down-none">
        <a class="nav-link" aria-label="UK Track and Trace document centre" href="https://uk-trackandtrace.dentsutracking.com/" target="_blank" rel="noopener"><i class="icon-info" aria-hidden="true"></i></a>
     </li>
     <li>
        <img src="assets/img/avatars/user_profile.jpg" alt="Default user avatar" style="margin-left: 10px; height: 35px;" class="img-avatar" />
        <p-tieredMenu #userMenu [model]="items" [popup]="true"></p-tieredMenu>
        <div class="p-d-flex p-flex-column profile" (click)="userMenu.toggle($event)">
           <div class="user-details">
              <h6 class="profile-name">{{user.firstName}} {{user.lastName}}</h6>
              <h6 class="profile-role">{{user.userType}}</h6>
           </div>
           <span class="dropdown-icon">
           <i role="img" class="pi pi-chevron-down" aria-hidden="true"></i>
           </span>
        </div>
     </li>
  </ul>
</div>

<!-- main layout -->
<div class="app-body">
  <div class="master-menu-nav">
    <!-- side master menu -->
    <p-sidebar styleClass="master-menu" [(visible)]="masterMenuMinimized"
              [baseZIndex]="10000" (onShow)="onShow($event)"
              [style]="{'overflow': 'scroll'}">
      <master-menu></master-menu>
    </p-sidebar>
  </div>

  <!-- Side Menu Content -->
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (click)="onSidebarClick($event)" [ngClass]="sidebarOpened ? 'open-nav' : 'close-nav'" styleClass="aside-nav" >
    <app-sidebar-nav [navItems]="portalItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
  </app-sidebar>

  <!-- Main Content -->
  <main class="main">
    <!-- Breadcrumb Component -->
    <cui-breadcrumb style="display:none!important;">
      <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
        </div>
      </li>
    </cui-breadcrumb>

    <!-- View container -->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<!-- main messages -->
<p-toast></p-toast>

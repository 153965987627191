import { UserType } from './shared/enums/UserType.enum';

interface NavAttributes {
  [propName: string]: any;
}

interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}

interface NavBadge {
  text: string;
  variant: string;
}

interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  key?: string;
  name?: string;
  href?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  system?: boolean;
}

export const navItems: NavData[] = [
  {
    key: 'GSLP_MEN01',
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'gscc-icon-68',
    attributes: {
      permissions: [],
      userTypes: [UserType.ADMIN, UserType.GOVERNMENT, UserType.INDUSTRY]
    }
  },
  {
    key: 'GSLP_MEN02',
    name: 'User management',
    url: 'core/users',
    icon: 'gscc-icon-26',
    attributes: {
      userTypes: [UserType.ADMIN]
    }
  },
  {
    key: 'GSLP_MEN03',
    name: 'Identifier codes',
    href: "#",
    url: '#',
    icon: 'gscc-icon-109',
    attributes: {
      jumpto: 'UK-GSEL',
      userTypes: [UserType.ADMIN]
    }
  },
  {
    key: 'GSLP_MEN04',
    name: 'API credentials',
    url: '/configuration/api-access',
    icon: 'gscc-icon-99',
    attributes: {
      userTypes: [UserType.ADMIN]
    }
  },
  {
    key: 'GSLP_MEN05',
    name: 'Code ordering',
    href: "#",
    url: '#',
    icon: 'gscc-icon-40',
    attributes: {
      jumpto: 'UK-GSDM',
      userTypes: [UserType.ADMIN]
    }
  },
  {
    key: 'GSLP_MEN06',
    name: 'Query tool',
    href: "#",
    url: '#',
    icon: 'gscc-icon-25',
    attributes: {
      jumpto: 'UK-GSCR',
      userTypes: [UserType.ADMIN]
    }
  },
  {
    key: 'GSLP_MEN07',
    name: 'Business Intelligence',
    //url: 'https://eu-west-1.quicksight.aws.amazon.com/sn/auth/signin?redirect_uri=https%3A%2F%2Feu-west-1.quicksight.aws.amazon.com%2Fsn%2Fstart%2Fdata-sets%3Fstate%3DhashArgs%2523%26isauthcode%3Dtrue',
    url: 'core/business-intelligence',
    icon: 'gscc-icon-33',
    attributes: {
      userTypes: [UserType.ADMIN],
      permissions: ['gsbi.accessToGsbi'], 
    }
  },
  {
    key: 'GSLP_MEN08',
    name: 'Support portal',
    href: "#",
    url: '#',
    icon: 'gscc-icon-62',
    attributes: {
      jumpto: 'UK-GSST',
      userTypes: [UserType.ADMIN]
    }
  }

];
export const environment = {
  production: true,
  pre: false,
  qa: false,
  code: "GSLP",
  module: "gscc-portals",
  portalTitle: "UK GSCC Landing Portal",
  basePath: "https://gslp-bff.uk-trackandtrace-dentsutracking.com",
  landingPortal: "https://gslp-fr.uk-trackandtrace-dentsutracking.com",
  dashboardPath: "",
  quicksighhtId: "c4418033-fe5a-4c09-802d-9a921b2479e0",
  portalVersion: "1.0.1"
};

import { Component } from '@angular/core';

@Component({
  selector: 'app-404',
  templateUrl: '404.component.html',
  styleUrls: ['404.component.css']
})
export class P404Component {
  constructor() { 
  }
}

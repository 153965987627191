import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import localeEn from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';

@Injectable({ providedIn: 'root' })
export class LocaleService {

    private _locale: string;

    set locale(value: string) {
        this._locale = value;
    }

    get locale(): string {
        return this._locale || 'en-GB';
    }

    registerCulture(culture: string) {
        if (!culture) {
            return;
        }

        this.locale = culture;

        switch (culture) {
            case 'en-GB': {
                registerLocaleData(localeEn);
                break;
            }
            case 'es': {
                registerLocaleData(localeEs);
                break;
            }
        }
    }
}
